import {addCustomSelect} from "./customSelect";
import {sendGrowthbookData} from "./growthbook";
import {getSyslocale} from "./locale";
/* eslint-disable no-undef */

const locations = [
    {
        zip: "form-bf-contact-input-zip",
        location: "form-bf-contact-input-location",
    },
    {zip: "wizard-bf-input-formZip", location: "wizard-bf-input-formLocation"},
    {zip: "card2_input_zip", location: "card2_input_city"},
    {zip: "card_input_zip", location: "card_input_city"},
    {zip: "card_input_previous_zip", location: "card_input_previous_city"},
    {zip: "form-cc-input-zip", location: "form-cc-input-city"},
    {
        zip: "form-pk-jobSituation-input-workerCompanyZip",
        location: "form-pk-jobSituation-input-workerCompanyLocation",
    },
    {
        zip: "form-pk-livingSituation-input-zip",
        location: "form-pk-livingSituation-input-location",
    },
    {
        zip: "form-pk-coApplicant-input-workerCompanyZip",
        location: "form-pk-coApplicant-input-workerCompanyLocation",
    },
    {
        zip: "form-pk-coApplicant-input-zip",
        location: "form-pk-coApplicant-input-location",
    },
    {
        zip: "form-pk-summary-input-zip",
        location: "form-pk-summary-input-location",
    },
    {
        zip: "form-pk-summary-input-workerCompanyZip",
        location: "form-pk-summary-input-workerCompanyLocation",
    },
    {
        zip: "form-pk-summary-coApplicant-input-zip",
        location: "form-pk-summary-coApplicant-input-location",
    },
];

function plzToLocation(plzId, locationId) {
    const plzInput = $("#" + plzId);
    const locationInput = $(locationId);
    const plz = plzInput.val();

    const countrySelectId = plzId
        .replace("input", "select")
        .replace("zip", "country")
        .replace("Zip", "Country");
    const countryCode = $(`#${countrySelectId}`).val() ?? "others";

    if (!plz || plz.length < 5 || countryCode !== "DE" || !/^\d+$/.test(plz)) {
        return;
    }

    if (locationInput.val()) {
        return;
    }

    $.ajax({
        url: "/plz.php",
        type: "GET",
        data: {plz},
    })
        .done(function (response) {
            const obj = JSON.parse(response);
            if (obj && obj.status === "ok" && obj.data && obj.data.cities.length) {
                // If more than one city for zip code is given, don't do anything
                const cities = obj.data.cities;
                if (cities.length > 1) return;
                if (locationInput.val()) {
                    return;
                }
                locationInput.val(obj.data.cities[0].ort).change();
            }
        })
        .fail(function (error) {
            console.error(error);
        });
}

function addZipListeners() {
    locations.forEach((obj) => {
        const zipId = obj.zip;
        const locationId = `#${obj.location}`;
        $(`#${zipId}`).on("input", function (e) {
            plzToLocation(zipId, locationId);
        });
    });
}

$('a[href^="#"]').on("click", function (e) {
    e.preventDefault();
    const target = this.hash;
    const $target = $(target);
    $("html, body")
        .stop()
        .animate(
            {
                scrollTop: $target.offset().top,
            },
            1,
            function () {
                window.location.hash = target;
            }
        );
});

// NAV

$(".burger").on("click", function (e) {
    $("nav").toggleClass("open");
    $(".phone").toggleClass("open");
    $(this).toggleClass("open");
    $("body").toggleClass("fixed");
});

// SLIDER

$(".bank-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 660,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 355,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".bank-items").show();

$(".bank-items1")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 660,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 355,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".bank-items1").show();

$(".finance_bank-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".finance_bank-items").show();

$(".partner-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".partner-items").show();

$(".rescheduling_bank-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".rescheduling_bank-items").show();

$(".fact-slider")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".fact-slider").show();

$(".icon-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true
                },
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".icon-items").show();

$(".employee-opinions")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".employee-opinions").show();

$(".three-items-slide")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".three-items-slide").show();

$(".four-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".four-items").show();

$(".six-items")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 6,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".six-items").show();

$(".twoDesk-slides")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 8,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 751,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".twoDesk-slides").show();

$(".credit-slides")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        dots: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1120,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 502,
                settings: {
                    slidesToShow: 4,
                    dots: false,
                    slidesToScroll: 1,
                },
            },
        ],
    });

$(".credit-slides").show();

$(".three-options")
    .not(".slick-initialized")
    .slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".three-options").show();

$(".about-contactPerson")
    .not(".slick-initialized")
    .slick({
        autoplaySpeed: 5000,
        slidesToShow: 6,
        dots: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 941,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                    slidesToScroll: 1,
                },
            },
        ],
    });
$(".about-contactPerson").show();

$(".about-imgMasonry").not(".slick-initialized").slick({
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: true,
    slidesToScroll: 1,
});
$(".about-imgMasonry").show();


$(".custom-select")
    .not(".template")
    .each(function () {
        addCustomSelect(this);
    });

$("#interaction-kr-locationSelectOffice").change(function () {
    $(".content").addClass("hidden");
    $("#content_" + $(this).val()).removeClass("hidden");
});

// MAP

$(window).on("scroll", function () {
    $(".pin").each(function () {
        if (isScrolledIntoView($(this))) {
            $(this).addClass("fall");
        } else {
            $(this).removeClass("fall");
        }
    });
});

function isScrolledIntoView(elem) {
    const docViewTop = $(window).scrollTop();
    const docViewBottom = docViewTop + $(window).height();

    const elemTop = $(elem).offset().top;
    const elemBottom = elemTop + $(elem).height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

const wordStates = document.querySelectorAll(".region-list__region");
const svgStates = document.querySelectorAll(".map__regions > *");

function removeAllOn() {
    wordStates.forEach(function (el) {
        el.classList.remove("on");
    });
    svgStates.forEach(function (el) {
        el.classList.remove("on");
    });
}

function addOnFromList(el) {
    const regionCode = el.getAttribute("data-region");
    const svgRegion = document.querySelector("#" + regionCode);
    el.classList.add("on");
    svgRegion.classList.add("on");
}

function addOnFromRegion(el) {
    const regionId = el.getAttribute("id");
    const wordRegion = document.querySelector("[data-region='" + regionId + "']");
    el.classList.add("on");
    wordRegion.classList.add("on");
}

// TODO
function formatPlaceholder(field, sysLocale, format) {
    if ($(field).hasClass("formattedPlaceholder")) return;
    numeral.locale("de");
    const placeholderValue = numeral(field.getAttribute("placeholder")).value();
    numeral.locale(sysLocale);
    const placeholderFormat = numeral(placeholderValue).format(format);
    field.setAttribute("placeholder", placeholderFormat);
}

wordStates.forEach(function (el) {
    el.addEventListener("mouseenter", function () {
        addOnFromList(el);
    });
    el.addEventListener("mouseleave", function () {
        removeAllOn();
    });

    el.addEventListener("touchstart", function () {
        removeAllOn();
        addOnFromList(el);
    });
});

svgStates.forEach(function (el) {
    el.addEventListener("mouseenter", function () {
        addOnFromRegion(el);
    });
    el.addEventListener("mouseleave", function () {
        removeAllOn();
    });

    el.addEventListener("touchstart", function () {
        removeAllOn();
        addOnFromRegion(el);
    });
});

$(".partnerForm").on("click", function (e) {
    const interest = $(e.currentTarget).data("interest");
    $("#mail-partner-form input[name=interest]").each(function () {
        if ($(this).val() === interest) {
            $(this).click();
        }
    });
});


// ACCORDION
$(document).on("click", ".toggle", function (e) {
    e.preventDefault();

    const $accordion = $(this).closest(".accordion");  // Scope to specific accordion
    const self = this;
    const $this = $(self);

    $accordion.find(".toggle.rotate")
        .filter(function (idx, el) {
            return el !== self;
        })
        .removeClass("rotate");

    $this.toggleClass("rotate");

    if ($this.next().hasClass("show")) {
        $this.next().removeClass("show");
        $this.next().slideUp(200);

        $this.children(".details").fadeOut(200, function () {
            $(this).text("Details einblenden +").fadeIn();
        });
    } else {
        const everyContent = $accordion.find(".inner");
        everyContent.removeClass("show");
        everyContent.slideUp(200);
        $accordion.find(".details")
            .fadeOut(200, function () {
                $(this).text("Details einblenden +").fadeIn();
            });

        $this.next().toggleClass("show");
        $this.next().slideToggle(200);

        $this.children(".details").fadeOut(150, function () {
            $(this).text("Details ausblenden –").fadeIn();
        });
    }
});


if (window.matchMedia("(max-width: 940px)").matches) {
    $(document).on("click", ".mobile-toggle", function (d) {
        d.preventDefault();

        const $accordion = $(this).closest(".accordion"); // Scope to specific accordion
        const self = this;
        const $this = $(self);

        $accordion.find(".mobile-toggle.rotate")
            .filter(function (idx, el) {
                return el !== self;
            })
            .removeClass("rotate");

        $this.toggleClass("rotate");

        if ($this.next().hasClass("show")) {
            $this.next().removeClass("show");
            $this.next().slideUp(200);

            $this.children(".details").fadeOut(180, function () {
                $(this).text("Details einblenden +").fadeIn();
            });
        } else {
            const everyContent = $accordion.find(".inner");
            everyContent.removeClass("show");
            everyContent.slideUp(200);
            $accordion.find(".details")
                .fadeOut(180, function () {
                    $(this).text("Details einblenden +").fadeIn();
                });

            $this.next().toggleClass("show");
            $this.next().slideToggle(200);

            $this.children(".details").fadeOut(180, function () {
                $(this).text("Details ausblenden –").fadeIn();
            });
        }
    });
}



// TOOLTIP

$(document).on("click", ".tooltip-icon", function (e) {
    e.preventDefault();

    const currentTooltip = $(e.currentTarget)
        .parent(".tooltip-text")
        .next(".tooltip-content");

    $(".tooltip-content")
        .filter(function (tooltip) {
            return tooltip !== currentTooltip;
        })
        .removeClass("show")
        .slideUp(150);

    // eslint-disable-next-line eqeqeq
    if (currentTooltip.css("display") == "none") {
        currentTooltip.addClass("show").slideDown(200);
    } else {
        currentTooltip.removeClass("show").slideUp(200);
    }
});

$(".tooltip .close").on("click", function (e) {
    $(e.currentTarget)
        .parents(".tooltip-content")
        .removeClass("show")
        .slideUp(200);
});

$("body").on("click", function (e) {
    const tooltips = $(".tooltip-content.show");
    if (tooltips.length) {
        e.preventDefault();
        tooltips.removeClass("show").slideUp(200);
    }
});

// Valid  Todo: überall valid Klasse hinzufügen
// $('input').on('input keyup', function () {
//     // valid and error class provided by validation plugin
//     // invalid and correct defined for styling
//     $('input.valid').prev('label').addClass('correct').removeClass('invalid')
//     $('input.error').prev('label').addClass('invalid').removeClass('correct')
// });
// Validation plugin does not validate while
// $('input').on('classChange', function () {
//     // valid and error class provided by validation plugin
//     // invalid and correct defined for styling
//     $('input.valid').prev('label').addClass('correct').removeClass('invalid')
//     $('input.error').prev('label').addClass('invalid').removeClass('correct')
// });

// opening the mail modal, also setting the recipient email via data-email attr
$(".openMailModal").on("click", function (e) {
    const recipientEmail = $(e.currentTarget).data("email");
    $("#mail-form").children("input[name=recipient_email]").val(recipientEmail);
    $(".modal-mail").addClass("show");
    $("body").addClass("fixed");
});

$(document).on("click", ".modal-mail .close", function () {
    $(".modal-mail").removeClass("show");
    $("#mail-form").trigger("reset");
    $("body").removeClass("fixed");
});

$("#mailform_submit").on("click", function (e) {
    e.preventDefault();
    // validate inputs
    const validator = window.mailform_validator;
    if (!validator.form()) {
        $(".error-summary").show();
        return;
    }
    $(this).closest(".iml-loader").addClass("show");
    // get form values
    const mailFormValues = $("#mail-form").serialize();
    // send form values to server
    $.ajax({
        url: "/submit_contactform.php",
        method: "POST",
        data: mailFormValues,
    })
        .done(function (res) {
            try {
                const response = JSON.parse(res);
                if (response.status === "OK") {
                    $("#mail-form").hide();
                    $("#mail-form")
                        .closest(".modal")
                        .find(".send-success")
                        .removeClass("hide");
                } else {
                    if (typeof window?.Sentry?.captureMessage === "function") {
                        window.Sentry.captureMessage(res);
                    }
                    $("#mail-form").hide();
                    $("#mail-form")
                        .closest(".modal")
                        .find(".send-failed")
                        .removeClass("hide");
                }
            } catch (error) {
                if (typeof window?.Sentry?.captureMessage === "function") {
                    window.Sentry.captureMessage(error);
                }
                $("#mail-form").hide();
                $("#mail-form")
                    .closest(".modal")
                    .find(".send-failed")
                    .removeClass("hide");
            }
        })
        .fail(function (jqXHR, textStatus, thrownError) {
            $("#mail-form").hide();
            $("#mail-form")
                .closest(".modal")
                .find(".send-failed")
                .removeClass("hide");
            if (typeof window?.Sentry?.captureMessage === "function") {
                window.Sentry.captureMessage(thrownError || jqXHR.statusText, {
                    extra: {
                        status: jqXHR.status,
                        error: thrownError || jqXHR.statusText,
                        response: jqXHR?.responseText?.substring(0, 100),
                    },
                });
            }
        });
});

$("#interaction-kr-sendFormBecomePartnerFormSubmit").on("click", function (e) {
    e.preventDefault();
    // validate inputs
    const validator = window.mailpartnerform_validator;
    if (!validator.form()) {
        $(".error-summary").show();
        return;
    }
    $(this).closest(".iml-loader").addClass("show");
    // get form values
    const mailFormValues = $("#mail-partner-form").serialize();
    // send form values to server
    $.ajax({
        url: "/submit_contactform.php",
        method: "POST",
        data: mailFormValues,
    })
        .done(function (res) {
            try {
                const response = JSON.parse(res);
                if (response.status === "OK") {
                    $("#mail-partner-form").hide();
                    $("#mail-partner-form")
                        .closest(".form")
                        .find(".send-success")
                        .removeClass("hide");
                } else {
                    if (typeof window?.Sentry?.captureMessage === "function") {
                        window.Sentry.captureMessage(res);
                    }
                    $("#mail-partner-form").hide();
                    $("#mail-partner-form")
                        .closest(".modal")
                        .find(".send-failed")
                        .removeClass("hide");
                }
            } catch (error) {
                if (typeof window?.Sentry?.captureMessage === "function") {
                    window.Sentry.captureMessage(error);
                }
                $("#mail-partner-form").hide();
                $("#mail-partner-form")
                    .closest(".modal")
                    .find(".send-failed")
                    .removeClass("hide");
            }
        })
        .fail(function (jqXHR, textStatus, thrownError) {
            $("#mail-partner-form").hide();
            $("#mail-partner-form")
                .closest(".modal")
                .find(".send-failed")
                .removeClass("hide");
            if (typeof window?.Sentry?.captureMessage === "function") {
                window.Sentry.captureMessage(thrownError || jqXHR.statusText, {
                    extra: {
                        status: jqXHR.status,
                        error: thrownError || jqXHR.statusText,
                        response: jqXHR?.responseText?.substring(0, 100),
                    },
                });
            }
        });
});

$(document).on("click", ".showForm", function () {
    $(".rescheduleForm").addClass("show");
    $(".showForm.hideSelf").addClass("hide");

    $([document.documentElement, document.body]).animate(
        {
            scrollTop: $("html").offset().top,
        },
        800
    );
});

$(document).on("click", ".mobile-dropdownTrigger", function () {
    $(".dropdown-item").slideToggle("open");
});


$(document).ready(function () {
    const ps = $(".output");
    const observer = new ResizeObserver((entries) => {
        entries.forEach(function (entry) {
            entry.target.classList[
                entry.target.scrollHeight > entry.contentRect.height ? "add" : "remove"
                ]("truncated");
        });
    });

    $(ps).each(function () {
        observer.observe(this);
    });

    addZipListeners();
});

document.addEventListener("DOMContentLoaded", () => {
    const format = "0,00.00";
    const sysLocale = getSyslocale();
    numeral.locale(sysLocale);

    const handleField = (field) => {
        field.type = "text";
        field.inputMode = "decimal"; // numeric und decimal funktioniert auf android; komma und punkt werden nagezeigt / decimal für iphone hat nur ein punkt und kein komma; numeric hat nur zahlen
        field.addEventListener("blur", function (e) {
            const newValue = this.value ? numeral(this.value).format(format) : null;
            $(e.currentTarget).data("value", numeral(this.value).value());
            this.value = newValue;
        });
    };

    const numberFields = [];
    const detectFields = (parent) => {
        const fields = [...parent.querySelectorAll("input[type=number]")].filter(
            (field) => field.parentElement.classList.contains("euro")
        );
        for (const field of fields) {
            handleField(field);
            if (numeral(field.getAttribute("placeholder")).value()) {
                formatPlaceholder(field, sysLocale, format);
                // class is checked in foramtPlaceholder function
                // creation of dynamic elements would trigger formatPlaceholder function twice
                $(field).addClass("formattedPlaceholder");
            }
        }

        numberFields.push(...fields);
        return fields;
    };

    document.querySelectorAll("form").forEach((form) => {
        const affectedFieldNames = [];
        const detectAffectedFieldNames = (parent) => {
            for (const field of detectFields(parent))
                if (form.contains(field) && !affectedFieldNames.includes(field.name))
                    affectedFieldNames.push(field.name);
        };
        detectAffectedFieldNames(form);

        Object.defineProperty(form, "formattedFields", {
            get: () => affectedFieldNames,
        });

        const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    for (const node of mutation.addedNodes) {
                        if (node.nodeType !== Node.ELEMENT_NODE) continue;

                        detectAffectedFieldNames(node);
                    }
                    for (const node of mutation.removedNodes) {
                        if (node.nodeType !== Node.ELEMENT_NODE) continue;

                        const inputs = [...node.querySelectorAll("input")];
                        if (node.tagName === "INPUT") inputs.push(node);
                        for (const input of inputs)
                            if (numberFields.includes(input))
                                affectedFieldNames.splice(
                                    affectedFieldNames.indexOf(input.name),
                                    1
                                );
                    }
                }
            }
        });
        observer.observe(form, {
            attributes: false,
            childList: true,
            subtree: true,
        });

        // form.addEventListener('submit', function(e){
        //     if(e.defaultPrevented) // validation kicked in
        //         return;

        //     e.preventDefault();

        //     const actualForm = form.cloneNode(true);
        //     for(const field of numberFields)
        //         if(form.contains(field))
        //             actualForm.querySelector(`[name=${field.name}]`).value = text2Number(field.value);
        //     actualForm.submit();
        // });
    });

    const fields = document.querySelectorAll("input[type=number]");
    for (const field of fields) {
        field.addEventListener("input", function () {
            // check bounds
            // if(this.max)
            // {
            //     const max = parseInt(this.max, 10);
            //     if(parseInt(this.value, 10) > max)
            //         this.value = max;
            // }
            // if(this.min)
            // {
            //     const min = parseInt(this.min, 10);
            //     if(parseInt(this.value, 10) < min)
            //         this.value = min;
            // }

            // check lengths
            if (this.max && this.value.length > this.max.length)
                this.value = this.value.substr(0, this.max.length);
        });
    }

    sendGrowthbookData();
});

function countJobs() {
    const countJobs = document.getElementsByClassName("row");
    const placeCountedJobs = document.getElementById("countedJobs");
    if (countJobs != null && placeCountedJobs != null) {
        placeCountedJobs.innerHTML = countJobs.length;
    }

    return this;
}

countJobs();

$(window).scroll(function () {
    const height = $(window).scrollTop();

    if (
        height > 150 &&
        window.matchMedia("screen and (max-width:940px)").matches
    ) {
        $(".table-of-contents").addClass("fixedScroll");
    } else {
        $(".table-of-contents").removeClass("fixedScroll");
    }
});

if (window.matchMedia("screen and (max-width:940px)").matches) {
    $(".table-of-contents").on("click", function (e) {
        $(".table-of-contents_nav").slideToggle();
        $(".table-of-contents_burger").toggleClass("rotate");
    });

    $('.explanatoryTexts a[href^="#"]').on("click", function (e) {
        e.preventDefault();
        const target = this.hash;
        const $target = $(target);
        if (history.pushState) {
            history.pushState(null, null, target);
        } else {
            location.hash = target;
        }
        $("html, body")
            .stop()
            .animate(
                {
                    scrollTop: $target.offset().top - 430,
                },
                900,
                "swing"
            );
    });
}

const requestAnimationFrame = window.requestAnimationFrame;

document.addEventListener('DOMContentLoaded', function () {
    const goTopButton = document.querySelector('[data-action="gotop"]');
    const windowViewPortHeight = window.innerHeight; // browser viewport height
    let isRequestingAnimationFrame = false;

    if (!goTopButton) {
        return;
    }

    goTopButton.addEventListener('click', function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    window.addEventListener('scroll', function () {
        if (!isRequestingAnimationFrame) {
            requestAnimationFrame(filterGoTopButtonVisibility);
            isRequestingAnimationFrame = true;
        }
    });

    function filterGoTopButtonVisibility() {
        const windowPageYOffset = document.documentElement.scrollTop;
        if (windowPageYOffset > windowViewPortHeight) {
            goTopButton.classList.add('show');
            isRequestingAnimationFrame = false;
        } else {
            goTopButton.classList.remove('show');
            requestAnimationFrame(filterGoTopButtonVisibility);
        }
    }
});

// TABLE OF CONTENT
document.addEventListener('DOMContentLoaded', function () {
    const toggleButton = document.getElementById('toggle-toc');
    const toc = document.querySelector('.table-of-content');
    const navLinks = document.querySelectorAll('.table-of-content_nav a');
    const ids = [];

    // IDs der Navigationslinks sammeln
    navLinks.forEach(element => {
        ids.push(element.hash);
    });

    // Überprüfen der Fensterbreite und Hinzufügen der Klasse 'hiddenTab' falls nötig
    function checkWindowSize() {
        if (toc) { // Überprüfen, ob das table-of-content existiert
            if (window.innerWidth < 1600) {
                toc.classList.add('hiddenTab');
            } else {
                toc.classList.remove('hiddenTab');
            }
        }
    }

    // Initiale Überprüfung der Fenstergröße
    checkWindowSize();

    // Event Listener für Fenstergrößenänderungen
    window.addEventListener('resize', checkWindowSize);

    // Toggle-Funktion für die Tabelle des Inhalts
    if (toggleButton) { // Sicherstellen, dass der Button existiert
        toggleButton.addEventListener('click', function () {
            if (toc) { // Sicherstellen, dass das TOC existiert
                toc.classList.toggle('hiddenTab');
            }
        });
    }

    // Scroll-Handler zur Aktualisierung aktiver Links
    const handleScroll = function () {
        const sections = document.querySelectorAll('div[id]');
        const windowHeight = window.innerHeight;
        let currentSection = '';

        sections.forEach(section => {
            const sectionTop = section.getBoundingClientRect().top;
            if (sectionTop <= windowHeight / 2 && sectionTop >= -section.offsetHeight && ids.includes(`#${section.id}`)) {
                currentSection = section.getAttribute('id');
            }
        });

        navLinks.forEach(link => {
            const isActive = link.getAttribute('href').substring(1) === currentSection;
            link.classList.toggle('active', isActive);
        });
    };

    // Scroll-Event Listener hinzufügen
    document.addEventListener('scroll', handleScroll);

    // Event Listener für Klicks auf Navigationslinks
    navLinks.forEach(link => {
        link.addEventListener('click', function () {
            if (window.innerWidth < 1600 && toc) { // Sicherstellen, dass das TOC existiert
                toc.classList.add('hiddenTab');
            }
        });
    });
});
